@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Kanit:wght@300&family=Mitr:wght@300&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
html,
body {
  font-family: 'Roboto', sans-serif !important;
}

.ant-picker-large {
  border-radius: 5px !important;
}

.ant-picker {
  border-radius: 3px;
  font-family: unset;
  font-size: 0.9rem;
  height: 40px !important;
  line-height: 0px !important;
}

.ant-picker:hover {
  border-color: #a0aec0;
}

.ant-picker-focused {
  border-color: #3182ce;
  box-shadow: none;
}

.ant-picker-cell-inner:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1 px solid #3182ce;
  border-radius: 2 px;
  content: '';
}

.ant-picker-today-btn {
  color: #3182ce;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #3182ce;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html,
body {
  font-family: 'Roboto', sans-serif !important;
}

